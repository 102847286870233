<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 605px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">イベント<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{ name: 'Dashboard' }"> ダッシュボード </router-link>
            </li>
            <li>
              <router-link to="/admin/event"> イベント </router-link>
            </li>
            <li><span>詳細</span></li>
          </ul>
        </nav>
      </div>
      <SuccessMessage v-if="successMessage" />

      <div class="toggle_wrap">
        <div class="toggle_dsn">
          <span class="modeon">表示</span>

          <router-link
            :to="{
              name: 'Event Edit',
              params: { id: $route.params.id },
            }"
          >
            編集</router-link
          >
        </div>
        <!--toggle_dsn-->
      </div>

      <div class="command_wrap command_bottom">
        <div class="leftarea btn_wrap">
          <div class="return_link">
            <router-link to="/admin/event" dusk="to_list_screen">
              <span class="icon"><i class="fas fa-chevron-left"></i></span>イベント一覧
            </router-link>
          </div>
          <div></div>
        </div>
        <!--leftarea-->
        <div class="rightarea">
          <!--commandtext_wrap-->
        </div>
        <!--rightarea-->
      </div>
      <!--command_wrap-->
      <Spinner v-if="spinner" />

      <div class="contentout_wrap">
        <div class="content_wrap twocolumn">
          <div class="box_wrap left">
            <h2 class="ttl_2">イベント詳細</h2>
            <div class="scr_outwrap">
              <div class="scr_wrap">
                <table class="formtable">
                  <tbody>
                    <tr>
                      <th class="wid_200px">タイトル</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div class="in_wrap">
                              <p class="f_500">{{ showEvent.title }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="wid_200px">本文</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default">
                                <p v-html="showEvent.content"></p>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>

                    <tr>
                      <th class="wid_200px">開催日程</th>
                      <td>
                        <div class="row_form_wrap">
                          <div class="row_wrap header">
                            <div class="col">
                              <p class="ttl_3">{{ "開始日時" }}</p>
                            </div>
                            <div class="col">
                              <p class="ttl_3">{{ "終了日時" }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="row_form_wrap">
                          <div class="row_wrap header">
                            <div class="col">
                              <p class="ttl_3">
                                {{
                                  showEvent.implementation_datetime != null
                                    ? format_date(
                                        showEvent.implementation_datetime.start_datetime
                                      )
                                    : ""
                                }}
                              </p>
                            </div>
                            <div class="col">
                              <p class="ttl_3">
                                {{
                                  showEvent.implementation_datetime != null
                                    ? format_date(
                                        showEvent.implementation_datetime.end_datetime
                                      )
                                    : ""
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="wid_200px">開催場所</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default">
                                <p v-html="showEvent.place"></p>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>
                    <tr>
                      <th class="wid_200px">定員（人）</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default">
                                <p v-html="showEvent.capacity"></p>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>
                    <tr>
                      <th class="wid_200px">イベントお問い合せ先</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default">
                                <p v-html="showEvent.contact"></p>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>

                    <tr>
                      <th class="wid_200px">関連資料</th>
                      <td>
                        <div class="row_form_wrap" v-if="viewFile.length > 0">
                          <div class="row_wrap header">
                            <div class="col">
                              <p class="ttl_3">タイトル</p>
                            </div>
                            <div class="col">
                              <p class="ttl_3">アップロードファイル</p>
                            </div>
                          </div>
                        </div>
                        <template v-if="viewFile.length > 0">
                          <div
                            class="row_form_wrap m_b20"
                            v-for="file in viewFile"
                            :key="file.id"
                          >
                            <div class="row_wrap" v-if="file.extension == 'pdf'">
                              <div class="col">
                                <div class="form_outwrap">
                                  <div class="wrap wid_100per celltxtarea m_b0 p_r0">
                                    <div class="in_wrap">
                                      <p class="f_500">
                                        {{ file.display_name }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div class="wrap wid_100per celltxtarea m_b0 p_r0">
                                    <div class="in_wrap">
                                      <a
                                        style="cursor: pointer"
                                        @click="
                                          getFileStoreage({
                                            file: file.storage_file_name,
                                            file_name: file.updaload_name,
                                          })
                                        "
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                            </div>
                            <!--row_wrap-->
                          </div>
                        </template>
                        <span v-else>関連資料なし</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--formtable-->
              </div>
              <!--scr_wrap-->
            </div>
            <!--scr_outwrap -->
          </div>
          <!--box_wrap-->
          <div class="box_wrap right">
            <h2 class="ttl_2">公開情報</h2>
            <div class="form_outwrap">
              <div class="wrap wid_100per p_r0">
                <p class="ttl_3">アイキャッチ</p>
                <template v-if="viewFile.length > 0">
                  <div class="in_wrap" v-for="file in viewFile" :key="file.id">
                    <img
                      v-if="file.extension == 'jpg'"
                      :src="'data:image/png;base64,' + file.base64"
                      alt=""
                    />
                  </div>
                </template>
              </div>

              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">公開ステータス</p></div>
                <div class="in_wrap">
                  <p>{{ showEvent.status }}</p>
                </div>
              </div>
              <!--wrap-->

              <div class="wrap wid_100per p_r0" style="display: none">
                <div><p class="ttl_3">カテゴリー</p></div>
                <div class="in_wrap">
                  <p class="f_500">
                    {{ getType }}
                  </p>
                </div>
              </div>
              <!--wrap-->

              <!--wrap-->
              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">記事登録</p></div>
                <div class="in_wrap" v-if="showEvent.user != ''">
                  <p>
                    登録日： {{ format_date(showEvent.created_at) }}
                    <br />
                    作成者：
                    {{
                      showEvent.create_user ? showEvent.create_user.last_name : "" + " "
                    }}
                    {{ showEvent.create_user ? showEvent.create_user.first_name : "" }}
                  </p>
                </div>
              </div>
              <!--wrap-->
              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">最終更新</p></div>
                <div class="in_wrap" v-if="showEvent.user != ''">
                  <p>
                    更新日：{{ format_date(showEvent.updated_at) }} <br />
                    作成者：
                    {{
                      showEvent.update_user ? showEvent.update_user.last_name : "" + " "
                    }}
                    {{ showEvent.update_user ? showEvent.update_user.first_name : "" }}
                  </p>
                </div>
              </div>

              <!--wrap-->
            </div>
            <!--form_outwrap-->
          </div>
          <div class="box_wrap left" style="display: none">
            <h2 class="ttl_2">申込フォーム</h2>
            <div class="scr_outwrap">
              <div class="scr_wrap">
                <table class="formtable">
                  <tbody>
                    <tr>
                      <th class="wid_20per">申込フォームの利用</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per">
                            <div
                              class="in_wrap"
                              dusk="event_reservation_form_check_result"
                            >
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th class="wid_20per">入力項目の追加</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per">
                            <div
                              class="in_wrap"
                              dusk="event_reservation_form_adding_items"
                            >
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--scr_wrap-->
            </div>
            <!--scr_outwrap-->
          </div>

          <div class="box_wrap left" v-if="allEntryData.length > 0">
            <form action="" method="post" class="right">
              <input
                type="hidden"
                name="_token"
                value="wgaXHw4SmZcVm2TdtW5HjGUl3xmAeRWRT8Cdk2mo"
              />
              <div class="btn_mic form_btn csv_btn">
                <input type="hidden" name="status" value="受付完了" />
                <input
                  type="hidden"
                  name="fileName"
                  value="2021/10/28 10:05test受付完了2021_11_24_22_38_29.csv"
                />
                <!-- <button type="submit">CSV出力</button> -->
              </div>
            </form>
            <form>
              <input
                type="hidden"
                name="_token"
                value="wgaXHw4SmZcVm2TdtW5HjGUl3xmAeRWRT8Cdk2mo"
              />
              <h2 class="ttl_2" dusk="reservation_type_secsion_受付完了">
                参加申込者一覧<span
                  >({{ showEvent.reserved }}名/{{ showEvent.capacity }}名)</span
                >
              </h2>
              <div class="tool_wrap">
                <div class="bulktool-reception">
                  <div class="m_r50">
                    <span>全</span><span class="selectednum num"></span
                    ><span>が選択済みです</span>
                  </div>
                  <div class="commandtext">
                    <button
                      name="submit"
                      type="submit"
                      value="受付完了"
                      dusk="uketsuke_kanryo_btn"
                    >
                      受付完了
                    </button>
                  </div>
                  <div class="commandtext">
                    <button
                      name="submit"
                      type="submit"
                      value="キャンセル待ち"
                      dusk="kyanseru_machi_btn"
                    >
                      キャンセル待ち
                    </button>
                  </div>
                  <div class="commandtext">
                    <button
                      name="submit"
                      type="submit"
                      value="キャンセル"
                      class="color_red"
                      dusk="kyanseru_btn"
                    >
                      キャンセル
                    </button>
                  </div>
                  <div class="commandtext" style="display: none">
                    <button
                      name="submit"
                      type="submit"
                      value="イベント中止"
                      class="color_red"
                      dusk="ibento_chushi"
                    >
                      イベント中止
                    </button>
                  </div>
                </div>
                <div class="command_wrap_inbox">
                  <div class="leftarea"></div>
                  <!--leftarea-->
                  <div class="rightarea">
                    <!--ページネーションエリアがが入ります-->
                  </div>
                  <!--rightarea-->
                </div>
                <!--command_wrap-->
              </div>
              <!--tool_wrap-->
              <div class="scr_outwrap">
                <div class="scr_wrap">
                  <table class="tbl event-reception">
                    <tbody>
                      <tr>
                        <!-- <th class="chkarea wid_10per">
                          <label class="checkbox_wrap" dusk="checkall">
                            <input
                              type="checkbox"
                              name=""
                              value=""
                              class="chkbox allchk"
                            /><span></span>
                          </label>
                        </th> -->
                        <th class="wid_15per">参加者</th>
                        <th class="wid_20per">メールアドレス</th>
                        <th class="wid_10per">予約日</th>
                        <th class="wid_15per t_c">ステータス</th>
                        <th class="wid_15per t_c">操作</th>
                      </tr>

                      <tr v-for="entry in allEntryData" :key="entry.id">
                        <!-- <td class="wid_15per">
                          <label class="checkbox_wrap" dusk="check_2"> </label>
                        </td> -->
                        <td class="v_m t_c">
                          <template v-if="entry.from_user != ''">
                            {{ entry.from_user ? entry.from_user.last_name : "" }}

                            {{ " " + entry.from_user ? entry.from_user.first_name : "" }}
                          </template>
                        </td>
                        <td class="v_m t_c en fw_400">
                          <template v-if="entry.from_user != ''">
                            {{ entry.from_user ? entry.from_user.email : "" }}
                          </template>
                        </td>

                        <td class="v_m t_c">
                          <template v-if="entry.from_user != ''">
                            {{
                              format_only_date(
                                entry.from_user ? entry.from_user.created_at : ""
                              )
                            }}
                          </template>
                        </td>
                        <td class="v_m t_c">{{ entry.status }}</td>
                        <td class="t_c v_m command_area" style="padding: 1em 0.6em">
                          <div class="commandlist">
                            <div class="txt" v-if="entry.status == '受付済み'">
                              <a
                                style="cursor: pointer"
                                @click="changeStatus(entry.id, 'キャンセル')"
                                dusk="sendmails_2"
                              >
                                キャンセル</a
                              >
                            </div>
                            <div class="txt" v-if="entry.status == 'キャンセル待ち'">
                              <a
                                style="cursor: pointer"
                                @click="changeStatus(entry.id, '受付済み')"
                                dusk="mail_create_2"
                                >受付済み
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--tbl-->
                </div>
                <!--scr_wrap-->
              </div>
              <!--scr_outwrap-->
            </form>
          </div>

          <!--box_wrap-->
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>
</template>

<script>
import $ from "jquery";
import "@/assets/control_panel/js/jq-function";
import { mapState, mapGetters, mapActions } from "vuex";
import SuccessMessage from "../../../components/control_panel/SuccessMessage.vue";
import Spinner from "../../../components/control_panel/Spinner.vue";
import moment from "moment";
export default {
  name: "Event Show",
  data() {
    return {
      success: false,
      allEntryData: [],
      profilePicture: "",
    };
  },
  components: {
    SuccessMessage,
    Spinner,
  },
  watch: {
    allEntry(entries) {
      this.allEntryData = entries;
    },
    updateAllEntry(entries) {
      this.allEntryData = entries;
      console.log(entries);
    },
    picture(img) {
      this.profilePicture = img != "" ? img[0].base64 : "";
    },
  },
  computed: {
    ...mapState("event", ["showEvent", "allEntry", "updateAllEntry"]),
    ...mapState("common", ["successMessage", "spinner"]),
    ...mapState("file", ["viewFile", "picture"]),
    ...mapGetters("file", ["getViewedFile"]),
    ...mapGetters("user", ["fullName"]),
    ...mapGetters("event", ["getType"]),
    ...mapState("user", ["userFullName"]),
  },
  created() {
    this.viewEvent(this.$route.params.id);
    this.getFileEvent(this.$route.params.id);
    this.getEntry(this.$route.params.id);
    this.getEventEyeCatch(this.$route.params.id);
  },
  methods: {
    /**
     * get notification
     * @author Manikandan
     *  @date 16/11/2021
     */
    ...mapActions("event", ["viewEvent", "getEntry", "updateEntry"]),

    /**
     * get file
     * @author Manikandan
     *  @date 16/11/2021
     */
    ...mapActions("file", ["getFileEvent", "getFileStoreage", "getEventEyeCatch"]),
    ...mapActions("common", ["setSpinnerStatus"]),

    /**
     * customize date coloumn
     * @author Manikandan
     *  @date 16/11/2021
     */
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY/MM/DD HH:mm");
      }
    },
    format_only_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY.MM.DD");
      }
    },

    changeStatus(entryId, status) {
      this.updateEntry({
        entryId: entryId,
        eventId: this.$route.params.id,
        status: {
          status: status,
        },
      });
    },
  },
  mounted() {
    //Jquery
    // youtubeがタグの構成上表示出来ないので以下でタグを変換している
    $(function () {
      $("figure.media").each(function () {
        var $y_url = $(this).children().attr("url").replace("https://youtu.be/", "");
        $y_url = $(this).children().attr("url").replace("https://www.youtube.com/watch?v=", "");
        $(this)
          .children()
          .replaceWith(
            "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
          );
          console.log($y_url);
        $(this)
          .children(".post_youtube")
          .attr("src", "https://www.youtube.com/embed/" + $y_url);
      });
    });
  },
};
</script>
<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
</style>
